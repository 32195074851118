import { useForm } from "react-hook-form";
import { Yup, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants

function ValidationSchema(translate) {
    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    return Yup.object({
        commsType: Yup.string(),
        commsRecipient: Yup.string().when('commsType', {
            is: 'Email',
            then: Yup.string().required(requiredMessage).matches("^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9\\-]+)\\.([a-zA-Z]{1,10})(\\s*;\\s*|\\s*$))*$", "Invalid recipient email(s)."),
            otherwise: Yup.string().required(requiredMessage).matches("^\\+[\\d\\s\\-\\(\\)]+$", "Invalid phone number. (e.g. +1xxx5550100)"),
        }),
        commsSubject: Yup.string().when('commsType', {
            is: 'Email',
            then: Yup.string().required(requiredMessage),
            otherwise: Yup.string(),
        }),
        commsContent: Yup.string().when('commsType', {
            is: 'Email',
            then: Yup.string().required(requiredMessage),
            otherwise: Yup.string().required(requiredMessage).max(140, "Maximum length of 140 allowed."),
        }),


    })


}

export default ValidationSchema
