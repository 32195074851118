import CommunicationUtilUrls from 'src/apiUrls/CommunicationUtilUrls'
import React, { useState, useEffect } from 'react';
import { components, useServices } from 'cng-web-lib'
import { useFormContext } from "react-hook-form";
import sweetalert from 'sweetalert'

const {
    button: { CngPrimaryButton, CngSecondaryButton },
    CngGridItem
} = components

function CustomButton(showNotification) {
    const { setValue, getValues, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const [disable, setDisable] = useState([]);
    useEffect(() => { setDisable(false) }, []);

    const resetForm = (type) => {
        reset();
        setValue("commsType", type, { shouldValidate: true });
        setDisable(false);
    }

    const alert = (type, status) => {
        if (status === "success") {
            sweetalert(type + " Sent", "", status);
        } else {
            sweetalert(type + " Submission Failed", "", "error");
        }
    }

    return (
        <CngGridItem style={{ paddingLeft: 12 }}>
            <CngPrimaryButton style={{ width: 100 }} disabled={disable} onClick={() => {
                trigger().then(
                    (result) => {
                        if (result) {
                            setDisable(true);
                            var values = getValues();
                            var type = getValues("commsType");

                            function onSuccess(response) {
                                var returnData = response.data
                                var errorMessages = returnData.errorMessages
                                if (response.status == 200 && returnData != null && errorMessages == null) {
                                    alert(type, "success");
                                    resetForm(type);
                                } else {
                                    alert(type, "error");
                                    resetForm(type);
                                }
                            }

                            function onError(error) {
                                alert(type, "error");
                                resetForm(type);
                            }

                            function onComplete() { }
                            const config = {}
                            securedSendRequest.execute('POST', CommunicationUtilUrls.POST, values, onSuccess, onError, onComplete, config, null);
                        }
                    })
            }}>Submit</CngPrimaryButton>

            <CngSecondaryButton style={{ marginLeft: '1.875rem', width: 100 }} disabled={disable} onClick={() => {
                var type = getValues("commsType");
                resetForm(type);
            }}>Reset</CngSecondaryButton>
        </CngGridItem>
    )
}

export default CustomButton