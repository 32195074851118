import CommunicationUtilUrls from 'src/apiUrls/CommunicationUtilUrls'
import FormProperties from './FormProperties'
import CustomButton from './CommunicationUtilCustomButton'
import React from 'react'
import { Grid } from '@material-ui/core'
import { components, BasePage } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngAddForm }
} = components

function CommunicationUtilPage(props) {
  const { id } = useParams()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          // create={{
          //   url: CommunicationUtilUrls.POST
          // }}
          id={id}
          renderButtonSection={() => {
            return (
              <CustomButton />
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CommunicationUtilPage
