import React, { useState } from 'react'
import ConveyanceMasterKeys from 'src/constants/locale/key/ConveyanceMaster'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import ConveyanceAddPage from './ConveyanceAddPage'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [id, setId] = useState('')
  const { translate } = useTranslation([
    Namespace.CONVEYANCE_MASTER,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let conveyance = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.TITLE
    )
    return {
      conveyance
    }
  }

  return (
    <ConveyanceAddPage
      history={history}
      showNotification={showNotification}
      onPostSubmitSuccess={(datum) => {
        setId(datum.id)
        setLockedOnFirstTab(false)
      }}
    />
  )
}

export default AddPage
