import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ConveyanceMasterKeys from 'src/constants/locale/key/ConveyanceMaster'
import { Grid, Card, Typography } from '@material-ui/core'
import { components, DataFlattener } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import LicensePlatesFormProperties from './LicensePlatesFormProperties'
import { useFormContext } from "react-hook-form";
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import CngSection from '../../components/cngcomponents/CngSection'
import LocalTable from '../../components/aciacehighway/LocalTable'
import DialogForm from '../../components/aciacehighway/DialogForm'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  conveyanceID: "",
  carrierCode: "",
  conveyanceNo: "",
  conveyanceType: "",
  email: "",
  aceId: "",
  vinNo: "",
  status: "DR",
  transponderNo: "",
  licensePlates: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, isView, shouldHideMap }) {
  const [formDialog, setFormDialog] = useState({ open: false, licensePlate: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, licensePlate: null })
  const { setValue, getValues, watch } = useFormContext()
  const status = watch('status')
  const licensePlates = watch('licensePlates')

  const { translate } = useTranslation(Namespace.CONVEYANCE_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    let conveyanceMaster = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.TITLE
    )
    let conveyanceID = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CONVEYANCE_I_D
    )
    let carrierCode = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CARRIER_CODE
    )
    let conveyanceNo = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CONVEYANCE_NO
    )
    let conveyanceType = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CONVEYANCE_TYPE
    )
    let email = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.EMAIL
    )
    let aceId = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.ACE_ID
    )
    let status = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.STATUS
    )
    let vinNo = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.VIN_NO
    )
    let transponderNo = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.TRANSPONDER_NO
    )
    let licensePlates = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.LicensePlates.TITLE
    )
    let licensePlateNo = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.LicensePlates.LICENSE_PLATE_NO
    )
    let countryofRegistration = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.LicensePlates.COUNTRYOF_REGISTRATION
    )
    let state = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.LicensePlates.STATE
    )

    return {
      conveyanceMaster,
      conveyanceID,
      carrierCode,
      conveyanceNo,
      conveyanceType,
      email,
      aceId,
      status,
      vinNo,
      transponderNo,
      licensePlates,
      licensePlateNo,
      countryofRegistration,
      state
    }
  }

  function handleAddLicensePlate(data) {
    const licensePlates = [...getValues('licensePlates'), data]

    setValue('licensePlates', licensePlates)
    setFormDialog({ open: false, licensePlate: null })
  }

  function handleDeleteLicensePlate() {
    if (!confirmDialog.licensePlate) return

    const licensePlates = [...getValues('licensePlates')].filter(
      (licensePlate) => licensePlate._id !== confirmDialog.licensePlate._id
    )

    setValue('licensePlates', licensePlates)
    setConfirmDialog({ open: false, licensePlate: null })
  }

  function handleEditLicensePlate(data) {
    const licensePlates = [...getValues('licensePlates')]
    const index = licensePlates.findIndex(
      (licensePlate) => licensePlate._id === data._id
    )

    licensePlates[index] = data

    setValue('licensePlates', licensePlates)
    setFormDialog({ open: false, licensePlate: null })
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.conveyanceMaster}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyanceID}>
                <CngTextField
                  name="conveyanceID"
                  label={translatedTextsObject.conveyanceID}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('conveyanceID', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode}>
                <CngTextField
                  name="carrierCode"
                  label={translatedTextsObject.carrierCode}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('carrierCode', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyanceNo}>
                <CngTextField
                  name="conveyanceNo"
                  label={translatedTextsObject.conveyanceNo}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('conveyanceNo', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyanceType}>
                <CngCodeMasterAutocompleteField name="conveyanceType" 
                  label={translatedTextsObject.conveyanceType} 
                  isRequired
                  disabled={disabled}
                  size='small'
                  codeType='ACE_HW_CONVEYANCE_TYPE'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
                <CngTextField
                  name="email"
                  label={translatedTextsObject.email}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.aceId}>
                <CngTextField
                  name="aceId"
                  label={translatedTextsObject.aceId}
                  disabled={status === 'AC' || disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vinNo}>
                <CngTextField
                  name="vinNo"
                  label={translatedTextsObject.vinNo}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
                <CngCodeMasterAutocompleteField 
                  name="status"
                  label={translatedTextsObject.status}
                  disabled
                  codeType='EQ_MASTER_STATUS'
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transponderNo}>
                <CngTextField
                  name="transponderNo"
                  label={translatedTextsObject.transponderNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <LocalTable
              columns={[
                {
                  field: 'licensePlateNo',
                  title: translatedTextsObject.licensePlateNo
                },
                {
                  field: 'countryofRegistration',
                  title: translatedTextsObject.countryofRegistration
                },
                {
                  field: 'state',
                  title: translatedTextsObject.state
                },
              ]}
              data={licensePlates}
              header={
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.licensePlates}
                </Typography>
              }
              {...(!isView && {
                actions: [
                  {
                    color: 'primary',
                    buttonProps: { color: 'secondary' },
                    label: 'Add License Plate',
                    icon: ['fal', 'plus-circle'],
                    onClick: () =>
                      setFormDialog({ open: true, licensePlate: null })
                  }
                ],
                rowActions: [
                  {
                    icon: ['fal', 'pen'],
                    onClick: (rowData) =>
                      setFormDialog({ open: true, licensePlate: rowData }),
                    tooltip: 'Edit'
                  },
                  {
                    icon: ['fal', 'trash'],
                    onClick: (rowData) =>
                      setConfirmDialog({ open: true, licensePlate: rowData }),
                    tooltip: 'Delete'
                  }
                ]
              })}
            />
          </Card>
        </Grid>
      </Grid>
      <DialogForm
        formProperties={LicensePlatesFormProperties}
        fullWidth
        isView={isView}
        maxWidth='md'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, licensePlate: null })}
        onSubmit={
          formDialog.licensePlate
            ? handleEditLicensePlate
            : handleAddLicensePlate
        }
        title={`${formDialog.licensePlate ? 'Edit' : 'Add New'} License Plate`}
        value={formDialog.licensePlate}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, licensePlate: null })}
        onCancel={() => setConfirmDialog({ open: false, licensePlate: null })}
        onConfirm={handleDeleteLicensePlate}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
	)
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.licensePlates.forEach((licensePlate, index) => {
    licensePlate['_id'] = index
  })

  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
