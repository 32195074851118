import React, { useState, useRef, useEffect, useCallback } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import { generatePath } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Chip, Grid } from '@material-ui/core'
import pathMap from '../../paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import ConveyanceMasterKeys from 'src/constants/locale/key/ConveyanceMaster'
import ConveyanceMasterApiUrls from 'src/apiUrls/ConveyanceMasterApiUrls'
import MedpidApiUrls from 'src/apiUrls/AceHighwayMedpidApiUrls'
import { getStatusMetadata } from '../../common/NACommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../components/aciacehighway/Table'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";

const {
  button: { CngButton },
  form: {
    field: { CngSelectField }
  },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog
} = components

const {
  CodeMaintenanceType,
  filter: { BETWEEN, EQUAL, LIKE, IN }
} = constants

function TablePage(props) {
  const { deleteRecord, securedSendRequest } = useServices()

  const tableRef = useRef()
  const { history, showNotification } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.CONVEYANCE_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [lookups, setLookups] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, conveyance: null })
  const [conveyanceSubmitDialog, setConveyanceSubmitDialog] = useState({ open: false, conveyance: [] })
  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'EQ_MASTER_STATUS' }
      ])
    ]).then(([status]) => {
      setLookups({ status })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let conveyanceID = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CONVEYANCE_I_D
    )
    let carrierCode = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CARRIER_CODE
    )
    let conveyanceNo = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CONVEYANCE_NO
    )
    let conveyanceType = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CONVEYANCE_TYPE
    )
    let email = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.EMAIL
    )
    let aceId = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.ACE_ID
    )
    let status = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.STATUS
    )
    let submissionDate = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMISSION_DATE
    )
    let responseDate = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.RESPONSE_DATE
    )
    let submitSentRecords = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMIT_SENT_RECORDS
    )
    let submitSuccessMessage = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMIT_SUCCESS_MESSAGE
    )
    let submitErrorMessage = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMIT_ERROR_MESSAGE
    )
    let editButton = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.EDIT_BUTTON
    )
    let editSentRecords = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.EDIT_SENT_RECORDS
    )
    let deleteButton = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.DELETE_BUTTON
    )
    let deleteSentRecords = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.DELETE_SENT_RECORDS
    )
    let submitButton = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMIT_BUTTON
    )

    return {
      conveyanceID,
      carrierCode,
      conveyanceNo,
      conveyanceType,
      email,
      aceId,
      status,
      submissionDate,
      responseDate,
      submitSentRecords,
      submitSuccessMessage,
      submitErrorMessage,
      editButton,
      editSentRecords,
      deleteButton,
      deleteSentRecords,
      submitButton
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'conveyanceID',
      title: translatedTextsObject.conveyanceID
    },
    {
      field: 'carrierCode',
      title: translatedTextsObject.carrierCode
    },
    {
      field: 'conveyanceNo',
      title: translatedTextsObject.conveyanceNo
    },
    {
      field: 'conveyanceType',
      title: translatedTextsObject.conveyanceType
    },
    {
      field: 'aceId',
      title: translatedTextsObject.aceId
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        if (!data.status) return

        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={getLookupValue('status', data.status)}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    },
    {
      field: 'submittedDate',
      title: translatedTextsObject.submissionDate,
      render: (rowData) =>
        rowData.submittedDate &&
        moment(rowData.submittedDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'responseDate',
      title: translatedTextsObject.responseDate,
      render: (rowData) =>
        rowData.responseDate &&
        moment(rowData.responseDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  // Currently unused, pending enhancment on Table component
  const getStatusFilterOptions = useCallback(() => {
    if (!lookups || !lookups?.status) return []

    return Object.entries(lookups.status).reduce(
      (acc, [key, value]) => [
        ...acc,
        {
          label: value,
          value: key,
          filterValue: { value: key }
        }
      ],
      []
    )
  }, [lookups])

  const filters = [
    {
      label: translatedTextsObject.conveyanceID,
      type: 'textfield',
      name: 'conveyanceID',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.carrierCode,
      type: 'textfield',
      name: 'carrierCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.conveyanceNo,
      type: 'textfield',
      name: 'conveyanceNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.conveyanceType,
      type: 'textfield',
      name: 'conveyanceType',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.aceId,
      type: 'textfield',
      name: 'aceId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: "Draft",
          value: "DR",
          filterValue: {
            value: "DR"
          }
        },
        {
          label: "Sent",
          value: "ST",
          filterValue: {
            value: "ST"
          }
        },
        {
          label: "Accepted",
          value: "AC",
          filterValue: {
            value: "AC"
          }
        },
        {
          label: "Rejected",
          value: "RJ",
          filterValue: {
            value: "RJ"
          }
        },
        {
          label: "Deactivated",
          value: "DA",
          filterValue: {
            value: "DA"
          }
        }
      ]
    },
    {
      label: translatedTextsObject.productDesc1,
      type: 'textfield',
      name: 'productDesc1',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    }
  ]

  function handleDeleteConveyance() {
    if (confirmDialog.conveyance) {
      deleteRecord.execute(ConveyanceMasterApiUrls.DELETE, confirmDialog.conveyance,
        () => {
          showNotification('success', 'Conveyance deleted successfully.')
          setConfirmDialog({ open: false, conveyance: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  function handleSubmitConveyance({ messageFunction }) {
    if (_.isEmpty(conveyanceSubmitDialog.conveyance)) return

    setLoading(true)
    let result = [...conveyanceSubmitDialog.conveyance]
    const fileForUserDetails = FileForUserGetUserDetails()

    result.forEach((conveyance) => {
      if (fileForUserDetails) {
        const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
          fileForUserDetails

        conveyance['fileForUserId'] = fileForUserId
        conveyance['fileForUserPartyId'] = fileForUserPartyId
        conveyance['fileForUserLoginId'] = fileForUserLoginId
      }

      conveyance['lastMsgAction'] = messageFunction
    })

    try {
      securedSendRequest.execute('POST', MedpidApiUrls.SUBMIT_ACE_CONVEYANCE, result,
        (response) => {
          const { errorMessages } = response.data[0]

          if (!_.isEmpty(errorMessages)) {
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
          }
          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }

          setConveyanceSubmitDialog({ open: false, conveyance: [] })
        },
        () => showNotification('error', translatedTextsObject.submitErrorMessage),
        () => setLoading(false)
      )
    } catch (error) {
      showNotification('error', translatedTextsObject.submitErrorMessage)
      setLoading(false)
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.CONV_MAST_ADD_VIEW)
            },
            label: 'Create Conveyance Master'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        fetch={{ url: ConveyanceMasterApiUrls.GET }}
        customExportData={{ url: ConveyanceMasterApiUrls.EXPORT_CSV, accId: user.loginId, partyId: FileForUserGetPartyId() }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === 'ST') {
            history.push(generatePath(pathMap.CONV_MAST_DETAILS_VIEW, { id: rowData.id }), { headerId: rowData.conveyanceID })
          } else {
            history.push(generatePath(pathMap.CONV_MAST_EDIT_VIEW, { id: rowData.id }), { headerId: rowData.conveyanceID })
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            label: 'Edit',
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.CONV_MAST_EDIT_VIEW, { id: rowData.id }),
                { headerId: rowData.conveyanceID }
              ),
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            disabled: (rowData) => rowData.status && rowData.status !== 'DR',
            label: 'Delete',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) => setConfirmDialog({ open: true, conveyance: rowData }),
            tooltip: (rowData) => rowData.status && rowData.status !== 'DR'
                ? translatedTextsObject.deleteSentRecords
                : null
          }
        ]}
        selectActions={[
          {
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: (rows) => setConveyanceSubmitDialog({ open: true, conveyance: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.submitSentRecords : null
            }
          }
        ]}
        showNotification={showNotification}
        persistSettings
        tableRef={tableRef}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, conveyance: null })}
        onCancel={() => setConfirmDialog({ open: false, conveyance: null })}
        onConfirm={handleDeleteConveyance}
        title='Discard'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
      <ConveyanceSubmitDialog
        onClose={() => setConveyanceSubmitDialog(false)}
        onSubmit={handleSubmitConveyance}
        open={conveyanceSubmitDialog.open}
        loading={loading}
      />
    </>
  )
}

function ConveyanceSubmitDialog(props) {
  const { onClose, onSubmit, open, loading } = props

  const methods = useForm({
    defaultValues: { messageFunction: '23' }
  })
  const { translate } = useTranslation(Namespace.CONVEYANCE_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const cancelButton = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.CANCEL_BUTTON
    )
    const submitButton = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMIT_BUTTON
    )
    const submitDialogTitle = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.SUBMIT_DIALOG_TITLE
    )
    const msgFuncType = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.MSG_FUNC_TYPE
    )
    const msgFuncAdd = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.MSG_FUNC_ADD
    )
    const msgFuncDeactivate = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.MSG_FUNC_DEACTIVATE
    )
    const msgFuncModify = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.MSG_FUNC_MODIFY
    )

    return {
      cancelButton,
      submitButton,
      submitDialogTitle,
      msgFuncType,
      msgFuncAdd,
      msgFuncDeactivate,
      msgFuncModify
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <FormProvider {...methods}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngSelectField
                  name='messageFunction'
                  label={translatedTextsObject.msgFuncType}
                  items={[
                    { text: translatedTextsObject.msgFuncAdd, value: '23' },
                    { text: translatedTextsObject.msgFuncDeactivate, value: '129' },
                    { text: translatedTextsObject.msgFuncModify, value: '36' }
                  ]}
                  isRequired
                  size='small'
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='space-between' spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton color='secondary' onClick={onClose} size='medium' disabled={loading}>
                      {translatedTextsObject.cancelButton}
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton onClick={methods.handleSubmit(onSubmit)} size='medium' disabled={loading}>
                      {translatedTextsObject.submitButton}
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      }
      dialogTitle={translatedTextsObject.submitDialogTitle}
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
    />
  )
}

export default TablePage
