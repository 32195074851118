import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import ConveyanceMasterKeys from 'src/constants/locale/key/ConveyanceMaster'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let minLengthErrorMessage = translate(
    Namespace.CONVEYANCE_MASTER,
    ConveyanceMasterKeys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.CONVEYANCE_MASTER,
    ConveyanceMasterKeys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.CONVEYANCE_MASTER,
    ConveyanceMasterKeys.Validations.ALPHANUMERIC
  )

  let emailErrorMessage = translate(
    Namespace.CONVEYANCE_MASTER,
    ConveyanceMasterKeys.Validations.EMAIL
  )

  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"

  return Yup.object({
    conveyanceID: Yup.string().max(15,maxLengthErrorMessage+" 15").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    carrierCode: Yup.string().max(4,maxLengthErrorMessage+" 4").min(4,minLengthErrorMessage+ " 4").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    conveyanceNo: Yup.string().max(35,maxLengthErrorMessage+" 35").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    conveyanceType: Yup.string().required(requiredMessage).nullable(),
    email:Yup.string().nullable().max(50,maxLengthErrorMessage+" 50").min(5,minLengthErrorMessage+ " 5").email(emailErrorMessage),
    aceId: Yup.string().max(10,maxLengthErrorMessage+" 10").matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    vinNo: Yup.string().max(30,maxLengthErrorMessage+" 30").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    transponderNo: Yup.string().max(30,maxLengthErrorMessage+" 30").matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    licensePlates: Yup.array(),
  })
}

export default makeValidationSchema
