import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams, useLocation } from 'react-router-dom'
import ConveyanceMasterKeys from 'src/constants/locale/key/ConveyanceMaster'
import Namespace from 'src/constants/locale/Namespace'
import ConveyanceViewPage from './ConveyanceViewPage'
import ResponseForm from '../acehighway/response/MedpidResponse'

const { CngTabs } = components

function ViewPage({ showNotification, history }) {
  const { id } = useParams()
  const loc = useLocation()
  const { translate } = useTranslation([Namespace.CONVEYANCE_MASTER])
  const translatedTextsObject = makeTranslatedTextsObject()
   
  function makeTranslatedTextsObject() {
    let conveyance = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.TITLE
    )
    let response = translate(
      Namespace.CONVEYANCE_MASTER,
      ConveyanceMasterKeys.RESPONSE_TITLE
    )
    return {
      conveyance,
      response
    }
  }


  return (
    <CngTabs
      headerColor='primary'
      tabs={[
        {
          tabName: translatedTextsObject.conveyance,
          tabContent: (
            <ConveyanceViewPage
              showNotification={showNotification}
              id={id}
              history={history}
            />
          )
        },
        {
          tabName: translatedTextsObject.response,
          tabContent: (
            <ResponseForm
              showNotification={showNotification}
              id={id}
              headerId={loc.state.headerId}
              docType='ACE_MEDPID_CONV'
            />
          )
        }
      ]}
    />
  );
}

export default ViewPage
